
export default {
  name: 'DefaultLayout',
  data() {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      items: [

      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      // title: 'Vuetify.js'
    }
  },
  head: {
    meta: [
      { name: 'google-adsense-account', content: 'ca-pub-9411006336667814' }
    ],
    link: [
      { rel: 'stylesheet', type: 'text/css', href: '/css/common.css' },
      { rel: 'stylesheet', type: 'text/css', href: '/css/style.css' },
      { rel: 'stylesheet', type: 'text/css', href: '/css/style.css' },
    ],
    script: [
      {
        type: 'application/ld+json',
        children: JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "자유마을",
          "url": "https://jayuvillage.com",
          "sameAs": [
            "https://www.youtube.com/@jayuvillage",
            "https://blog.naver.com/jayu_village"
          ]
          // 기타 필요한 스키마 정보를 여기에 추가
        }),
        json: true // 이 옵션은 Nuxt 3에서 JSON-LD 스크립트를 처리하는 데 필요할 수 있습니다.
      }
    ]
  },
  watch: {
    $route(to, from) {
      window.scrollTo(0, 0);

      if (this.$auth.user && this.$auth.user.id)
        this.$axios.get("/api/users/hasNewNotice")
          .then(response => {
            this.$auth.setUser({
              ...this.$auth.user,
              has_new_notice: response.data.data
            });
          });

      if (this.$auth.user && !this.$auth.user.id)
        this.$auth.setUser(null);
    },

    district(to, from) {
      window.scrollTo(0, 0);
    },
  },
  methods: {
    async logout() {
      this.$store.commit("changeDistrict", {
        id: 0,
        district: "자유마을"
      });
      await this.$auth.logout();
    },
  },

  computed: {
    district() {
      return this.$store.state.district;
    }
  },
  mounted() {
    if (!Kakao.isInitialized()) {
      Kakao.init('0b676e15765c46418fa53c1333910c0a');
    }
    if (window.thruFlutter) {
      this.$store.commit("setFlutterState", true);
      window.thruFlutter.postMessage(this.$store.state.isFlutter);

    } else {
      this.$store.commit("setFlutterState", false);

    }

  }
}
