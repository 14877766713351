import AOS from 'aos';
import 'aos/dist/aos.css';

export default ({ app }) => {
  app.AOS = new AOS.init({
    offset: 120,
    delay: 100,
    duration: 1000,
    easing: 'ease-in-out',
    once: false,
    mirror: false,
    anchorPlacement: 'top-top',
  }); // or any other options you need
}