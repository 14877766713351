
export default {
  data() {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      items: [

      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      // title: 'Vuetify.js'
    }
  },
  head: {
    link: [
      { rel: 'stylesheet', type: 'text/css', href: '/css/common.css' },
      { rel: 'stylesheet', type: 'text/css', href: '/css/style.css' },
      { rel: 'stylesheet', type: 'text/css', href: '/css/style.css' },
    ],
    script: [
    ]
  },
  watch: {
    

    district(to, from) {
      window.scrollTo(0, 0);
    },
  },
  methods: {
    // async logout() {
    //   this.$store.commit("changeDistrict", {
    //     id: 0,
    //     district: "자유마을"
    //   });
    //   await this.$auth.logout();
    // },
  },

  computed: {
   
  },
  mounted() {
    if(!Kakao.isInitialized()) {
      Kakao.init('0b676e15765c46418fa53c1333910c0a');
    }
  }
}
