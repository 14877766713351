import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7fbb0e54 = () => interopDefault(import('../pages/activity/index.vue' /* webpackChunkName: "pages/activity/index" */))
const _d2067860 = () => interopDefault(import('../pages/bridge/index.vue' /* webpackChunkName: "pages/bridge/index" */))
const _3937a7df = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _089f211a = () => interopDefault(import('../pages/contacts/index.vue' /* webpackChunkName: "pages/contacts/index" */))
const _288d5349 = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _3639afa0 = () => interopDefault(import('../pages/eventPosts/index.vue' /* webpackChunkName: "pages/eventPosts/index" */))
const _f1f418ec = () => interopDefault(import('../pages/fav/index.vue' /* webpackChunkName: "pages/fav/index" */))
const _f1f3e734 = () => interopDefault(import('../pages/flipTest/index.vue' /* webpackChunkName: "pages/flipTest/index" */))
const _9ee98c34 = () => interopDefault(import('../pages/index_old.vue' /* webpackChunkName: "pages/index_old" */))
const _e796e69c = () => interopDefault(import('../pages/info.vue' /* webpackChunkName: "pages/info" */))
const _3b46af18 = () => interopDefault(import('../pages/infos/index.vue' /* webpackChunkName: "pages/infos/index" */))
const _467d5500 = () => interopDefault(import('../pages/main/index.vue' /* webpackChunkName: "pages/main/index" */))
const _32ca9148 = () => interopDefault(import('../pages/myDistricts/index.vue' /* webpackChunkName: "pages/myDistricts/index" */))
const _1ec8a402 = () => interopDefault(import('../pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _03c4230a = () => interopDefault(import('../pages/notices/index.vue' /* webpackChunkName: "pages/notices/index" */))
const _4b58f140 = () => interopDefault(import('../pages/onlineCongregation/index.vue' /* webpackChunkName: "pages/onlineCongregation/index" */))
const _6469000c = () => interopDefault(import('../pages/organization/index.vue' /* webpackChunkName: "pages/organization/index" */))
const _7d6b6501 = () => interopDefault(import('../pages/politic/index.vue' /* webpackChunkName: "pages/politic/index" */))
const _7c59e942 = () => interopDefault(import('../pages/posts/index.vue' /* webpackChunkName: "pages/posts/index" */))
const _4e93b92a = () => interopDefault(import('../pages/posts_old/index.vue' /* webpackChunkName: "pages/posts_old/index" */))
const _d4f3ba54 = () => interopDefault(import('../pages/qnas/index.vue' /* webpackChunkName: "pages/qnas/index" */))
const _6ae95e9c = () => interopDefault(import('../pages/quiz/index.vue' /* webpackChunkName: "pages/quiz/index" */))
const _179c67b3 = () => interopDefault(import('../pages/rank/index.vue' /* webpackChunkName: "pages/rank/index" */))
const _10233e27 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _6e508e01 = () => interopDefault(import('../pages/researchPoll/index.vue' /* webpackChunkName: "pages/researchPoll/index" */))
const _4656406e = () => interopDefault(import('../pages/scraps/index.vue' /* webpackChunkName: "pages/scraps/index" */))
const _63c01e4e = () => interopDefault(import('../pages/share/index.vue' /* webpackChunkName: "pages/share/index" */))
const _01bb8fa9 = () => interopDefault(import('../pages/somoim/index.vue' /* webpackChunkName: "pages/somoim/index" */))
const _304b355a = () => interopDefault(import('../pages/staffs/index.vue' /* webpackChunkName: "pages/staffs/index" */))
const _5619f3ba = () => interopDefault(import('../pages/stuff/index.vue' /* webpackChunkName: "pages/stuff/index" */))
const _3088133e = () => interopDefault(import('../pages/survey/index.vue' /* webpackChunkName: "pages/survey/index" */))
const _1032cece = () => interopDefault(import('../pages/test/index.vue' /* webpackChunkName: "pages/test/index" */))
const _007c84c6 = () => interopDefault(import('../pages/webtoon/index.vue' /* webpackChunkName: "pages/webtoon/index" */))
const _7b17d33d = () => interopDefault(import('../pages/auth/edit.vue' /* webpackChunkName: "pages/auth/edit" */))
const _197c1d74 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _7be58006 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _6cb429ce = () => interopDefault(import('../pages/auth/password.vue' /* webpackChunkName: "pages/auth/password" */))
const _35c54c54 = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _60f0f0c0 = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _d988b174 = () => interopDefault(import('../pages/chat/ask.vue' /* webpackChunkName: "pages/chat/ask" */))
const _15719ebf = () => interopDefault(import('../pages/chat/create.vue' /* webpackChunkName: "pages/chat/create" */))
const _4f93856f = () => interopDefault(import('../pages/chat/live.vue' /* webpackChunkName: "pages/chat/live" */))
const _5f75fa38 = () => interopDefault(import('../pages/chat/location.vue' /* webpackChunkName: "pages/chat/location" */))
const _02df4af6 = () => interopDefault(import('../pages/chat/login.vue' /* webpackChunkName: "pages/chat/login" */))
const _928e53d6 = () => interopDefault(import('../pages/chat/test.vue' /* webpackChunkName: "pages/chat/test" */))
const _425a8aa8 = () => interopDefault(import('../pages/chat/view.vue' /* webpackChunkName: "pages/chat/view" */))
const _24fe7d02 = () => interopDefault(import('../pages/confirm/jay.vue' /* webpackChunkName: "pages/confirm/jay" */))
const _34b0df67 = () => interopDefault(import('../pages/confirm/jay_old.vue' /* webpackChunkName: "pages/confirm/jay_old" */))
const _cb0b8120 = () => interopDefault(import('../pages/confirm/ref.vue' /* webpackChunkName: "pages/confirm/ref" */))
const _32f94ce4 = () => interopDefault(import('../pages/contacts/create.vue' /* webpackChunkName: "pages/contacts/create" */))
const _5080673f = () => interopDefault(import('../pages/contacts/evaluateList.vue' /* webpackChunkName: "pages/contacts/evaluateList" */))
const _a0009748 = () => interopDefault(import('../pages/contents/about.vue' /* webpackChunkName: "pages/contents/about" */))
const _66da575f = () => interopDefault(import('../pages/contents/customer.vue' /* webpackChunkName: "pages/contents/customer" */))
const _d879db6c = () => interopDefault(import('../pages/contents/declares.vue' /* webpackChunkName: "pages/contents/declares" */))
const _050c9b8c = () => interopDefault(import('../pages/contents/greeting.vue' /* webpackChunkName: "pages/contents/greeting" */))
const _d5532bae = () => interopDefault(import('../pages/contents/privacyPolicy.vue' /* webpackChunkName: "pages/contents/privacyPolicy" */))
const _5109e924 = () => interopDefault(import('../pages/contents/settings.vue' /* webpackChunkName: "pages/contents/settings" */))
const _8e9ee1c4 = () => interopDefault(import('../pages/eventPosts/create.vue' /* webpackChunkName: "pages/eventPosts/create" */))
const _7f6b747e = () => interopDefault(import('../pages/game/bricks/index.vue' /* webpackChunkName: "pages/game/bricks/index" */))
const _1d38da93 = () => interopDefault(import('../pages/game/shooters/index.vue' /* webpackChunkName: "pages/game/shooters/index" */))
const _3373be32 = () => interopDefault(import('../pages/infos/approvals.vue' /* webpackChunkName: "pages/infos/approvals" */))
const _c164b85c = () => interopDefault(import('../pages/infos/commissioner_namelist.vue' /* webpackChunkName: "pages/infos/commissioner_namelist" */))
const _de269e6c = () => interopDefault(import('../pages/infos/create.vue' /* webpackChunkName: "pages/infos/create" */))
const _0ef0b11f = () => interopDefault(import('../pages/infos/create_daily_report.vue' /* webpackChunkName: "pages/infos/create_daily_report" */))
const _6e18a668 = () => interopDefault(import('../pages/infos/daily_report.vue' /* webpackChunkName: "pages/infos/daily_report" */))
const _7764e977 = () => interopDefault(import('../pages/infos/namelist.vue' /* webpackChunkName: "pages/infos/namelist" */))
const _f4a2932e = () => interopDefault(import('../pages/mypage/attendance.vue' /* webpackChunkName: "pages/mypage/attendance" */))
const _4e99dde0 = () => interopDefault(import('../pages/mypage/card.vue' /* webpackChunkName: "pages/mypage/card" */))
const _6995f0c3 = () => interopDefault(import('../pages/mypage/choochun.vue' /* webpackChunkName: "pages/mypage/choochun" */))
const _76746df4 = () => interopDefault(import('../pages/mypage/comments.vue' /* webpackChunkName: "pages/mypage/comments" */))
const _6197e6a8 = () => interopDefault(import('../pages/mypage/likePosts.vue' /* webpackChunkName: "pages/mypage/likePosts" */))
const _c753085e = () => interopDefault(import('../pages/mypage/mapView.vue' /* webpackChunkName: "pages/mypage/mapView" */))
const _3141c8d0 = () => interopDefault(import('../pages/mypage/meetings.vue' /* webpackChunkName: "pages/mypage/meetings" */))
const _76beaa40 = () => interopDefault(import('../pages/mypage/newcard.vue' /* webpackChunkName: "pages/mypage/newcard" */))
const _2a05dda0 = () => interopDefault(import('../pages/mypage/participants.vue' /* webpackChunkName: "pages/mypage/participants" */))
const _714ee2fa = () => interopDefault(import('../pages/mypage/posts.vue' /* webpackChunkName: "pages/mypage/posts" */))
const _4f466da1 = () => interopDefault(import('../pages/mypage/qr.vue' /* webpackChunkName: "pages/mypage/qr" */))
const _418bf598 = () => interopDefault(import('../pages/mypage/sharePosts.vue' /* webpackChunkName: "pages/mypage/sharePosts" */))
const _c716ee18 = () => interopDefault(import('../pages/notices/create.vue' /* webpackChunkName: "pages/notices/create" */))
const _408470a5 = () => interopDefault(import('../pages/organization/brief/index.vue' /* webpackChunkName: "pages/organization/brief/index" */))
const _47b619ee = () => interopDefault(import('../pages/organization/chart/index.vue' /* webpackChunkName: "pages/organization/chart/index" */))
const _6c3386a9 = () => interopDefault(import('../pages/organization/list/index.vue' /* webpackChunkName: "pages/organization/list/index" */))
const _28660ab0 = () => interopDefault(import('../pages/organization/manage/index.vue' /* webpackChunkName: "pages/organization/manage/index" */))
const _66c08069 = () => interopDefault(import('../pages/organization/myTown/index.vue' /* webpackChunkName: "pages/organization/myTown/index" */))
const _56c8e882 = () => interopDefault(import('../pages/organization/report/index.vue' /* webpackChunkName: "pages/organization/report/index" */))
const _8312c4ae = () => interopDefault(import('../pages/popular/rank/index.vue' /* webpackChunkName: "pages/popular/rank/index" */))
const _5f736860 = () => interopDefault(import('../pages/popular/statistics/index.vue' /* webpackChunkName: "pages/popular/statistics/index" */))
const _a8d09258 = () => interopDefault(import('../pages/posts_old/create.vue' /* webpackChunkName: "pages/posts_old/create" */))
const _369789bc = () => interopDefault(import('../pages/posts/create.vue' /* webpackChunkName: "pages/posts/create" */))
const _42f282a8 = () => interopDefault(import('../pages/qnas/create.vue' /* webpackChunkName: "pages/qnas/create" */))
const _df59b36c = () => interopDefault(import('../pages/qnas/list.vue' /* webpackChunkName: "pages/qnas/list" */))
const _423f9ddf = () => interopDefault(import('../pages/quiz/complete.vue' /* webpackChunkName: "pages/quiz/complete" */))
const _00cfc0a4 = () => interopDefault(import('../pages/staffs/create.vue' /* webpackChunkName: "pages/staffs/create" */))
const _43de608d = () => interopDefault(import('../pages/staffs/manage.vue' /* webpackChunkName: "pages/staffs/manage" */))
const _43188c30 = () => interopDefault(import('../pages/stuff/assembly/index.vue' /* webpackChunkName: "pages/stuff/assembly/index" */))
const _36e02d3c = () => interopDefault(import('../pages/stuff/education/index.vue' /* webpackChunkName: "pages/stuff/education/index" */))
const _62b30460 = () => interopDefault(import('../pages/stuff/mission/index.vue' /* webpackChunkName: "pages/stuff/mission/index" */))
const _13dff8a2 = () => interopDefault(import('../pages/survey/list/index.vue' /* webpackChunkName: "pages/survey/list/index" */))
const _26345db0 = () => interopDefault(import('../pages/survey/survey_lee.vue' /* webpackChunkName: "pages/survey/survey_lee" */))
const _2b29828a = () => interopDefault(import('../pages/test/t.vue' /* webpackChunkName: "pages/test/t" */))
const _0bb4bf4c = () => interopDefault(import('../pages/volunteer/listView.vue' /* webpackChunkName: "pages/volunteer/listView" */))
const _1e09ed8c = () => interopDefault(import('../pages/volunteer/register.vue' /* webpackChunkName: "pages/volunteer/register" */))
const _24d4d982 = () => interopDefault(import('../pages/infos/branch_manager/commissioner_namelist.vue' /* webpackChunkName: "pages/infos/branch_manager/commissioner_namelist" */))
const _408b8f4a = () => interopDefault(import('../pages/infos/branch_manager/daily_report.vue' /* webpackChunkName: "pages/infos/branch_manager/daily_report" */))
const _0064bc01 = () => interopDefault(import('../pages/infos/general_manager/daily_report.vue' /* webpackChunkName: "pages/infos/general_manager/daily_report" */))
const _33fd8e47 = () => interopDefault(import('../pages/mypage/bannerPicture/edit.vue' /* webpackChunkName: "pages/mypage/bannerPicture/edit" */))
const _72571a71 = () => interopDefault(import('../pages/organization/chart/leave/index.vue' /* webpackChunkName: "pages/organization/chart/leave/index" */))
const _db8a0a3c = () => interopDefault(import('../pages/organization/manage/appoint/index.vue' /* webpackChunkName: "pages/organization/manage/appoint/index" */))
const _2c7ac7d0 = () => interopDefault(import('../pages/organization/manage/approval/index.vue' /* webpackChunkName: "pages/organization/manage/approval/index" */))
const _137185fc = () => interopDefault(import('../pages/organization/manage/approvalRequests/index.vue' /* webpackChunkName: "pages/organization/manage/approvalRequests/index" */))
const _34a5b76b = () => interopDefault(import('../pages/organization/manage/dismiss/index.vue' /* webpackChunkName: "pages/organization/manage/dismiss/index" */))
const _4c3337b6 = () => interopDefault(import('../pages/organization/manage/distribute/index.vue' /* webpackChunkName: "pages/organization/manage/distribute/index" */))
const _7ab51f22 = () => interopDefault(import('../pages/organization/manage/myApprovalList/index.vue' /* webpackChunkName: "pages/organization/manage/myApprovalList/index" */))
const _9cdedaf6 = () => interopDefault(import('../pages/organization/manage/resign/index.vue' /* webpackChunkName: "pages/organization/manage/resign/index" */))
const _3b173e60 = () => interopDefault(import('../pages/organization/myTown/searchPage.vue' /* webpackChunkName: "pages/organization/myTown/searchPage" */))
const _3cdd0e04 = () => interopDefault(import('../pages/organization/report/list/index.vue' /* webpackChunkName: "pages/organization/report/list/index" */))
const _15e2e68a = () => interopDefault(import('../pages/organization/report/survey_lee.vue' /* webpackChunkName: "pages/organization/report/survey_lee" */))
const _2b9192c5 = () => interopDefault(import('../pages/organization/report/survey2.vue' /* webpackChunkName: "pages/organization/report/survey2" */))
const _269b1ce8 = () => interopDefault(import('../pages/organization/statistic/branch/index.vue' /* webpackChunkName: "pages/organization/statistic/branch/index" */))
const _ccb28586 = () => interopDefault(import('../pages/organization/statistic/state/index.vue' /* webpackChunkName: "pages/organization/statistic/state/index" */))
const _1b61d25e = () => interopDefault(import('../pages/stuff/mission/create.vue' /* webpackChunkName: "pages/stuff/mission/create" */))
const _3536b4e4 = () => interopDefault(import('../pages/stuff/mission/createChild.vue' /* webpackChunkName: "pages/stuff/mission/createChild" */))
const _08e69de1 = () => interopDefault(import('../pages/organization/chart/leave/resign.vue' /* webpackChunkName: "pages/organization/chart/leave/resign" */))
const _57fdce5d = () => interopDefault(import('../pages/organization/manage/dismiss/dismissMember.vue' /* webpackChunkName: "pages/organization/manage/dismiss/dismissMember" */))
const _89cb4cec = () => interopDefault(import('../pages/organization/manage/distribute/List.vue' /* webpackChunkName: "pages/organization/manage/distribute/List" */))
const _f91b9904 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _51afc404 = () => interopDefault(import('../pages/organization/brief/sub/_id.vue' /* webpackChunkName: "pages/organization/brief/sub/_id" */))
const _7860d764 = () => interopDefault(import('../pages/organization/manage/approvalRequests/_id.vue' /* webpackChunkName: "pages/organization/manage/approvalRequests/_id" */))
const _31e4820a = () => interopDefault(import('../pages/organization/manage/myApprovalList/_id.vue' /* webpackChunkName: "pages/organization/manage/myApprovalList/_id" */))
const _0cc27d28 = () => interopDefault(import('../pages/organization/report/list/_id.vue' /* webpackChunkName: "pages/organization/report/list/_id" */))
const _b95f4f66 = () => interopDefault(import('../pages/organization/brief/_id.vue' /* webpackChunkName: "pages/organization/brief/_id" */))
const _8e711c32 = () => interopDefault(import('../pages/organization/report/_id.vue' /* webpackChunkName: "pages/organization/report/_id" */))
const _5412c270 = () => interopDefault(import('../pages/stuff/mission/_id.vue' /* webpackChunkName: "pages/stuff/mission/_id" */))
const _7a1088ec = () => interopDefault(import('../pages/survey/list/_id.vue' /* webpackChunkName: "pages/survey/list/_id" */))
const _1e84b8be = () => interopDefault(import('../pages/activity/_id.vue' /* webpackChunkName: "pages/activity/_id" */))
const _94a1cbf0 = () => interopDefault(import('../pages/eventPosts/_id.vue' /* webpackChunkName: "pages/eventPosts/_id" */))
const _5999401c = () => interopDefault(import('../pages/notices/_id.vue' /* webpackChunkName: "pages/notices/_id" */))
const _2331fe12 = () => interopDefault(import('../pages/posts_old/_id.vue' /* webpackChunkName: "pages/posts_old/_id" */))
const _d71557ac = () => interopDefault(import('../pages/posts/_id.vue' /* webpackChunkName: "pages/posts/_id" */))
const _6ccae2be = () => interopDefault(import('../pages/qnas/_id.vue' /* webpackChunkName: "pages/qnas/_id" */))
const _2f365804 = () => interopDefault(import('../pages/quiz/_id.vue' /* webpackChunkName: "pages/quiz/_id" */))
const _627a4071 = () => interopDefault(import('../pages/scraps/_id.vue' /* webpackChunkName: "pages/scraps/_id" */))
const _670b6851 = () => interopDefault(import('../pages/somoim/_id.vue' /* webpackChunkName: "pages/somoim/_id" */))
const _58ec8345 = () => interopDefault(import('../pages/webtoon/_id.vue' /* webpackChunkName: "pages/webtoon/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/activity",
    component: _7fbb0e54,
    name: "activity"
  }, {
    path: "/bridge",
    component: _d2067860,
    name: "bridge"
  }, {
    path: "/chat",
    component: _3937a7df,
    name: "chat"
  }, {
    path: "/contacts",
    component: _089f211a,
    name: "contacts"
  }, {
    path: "/event",
    component: _288d5349,
    name: "event"
  }, {
    path: "/eventPosts",
    component: _3639afa0,
    name: "eventPosts"
  }, {
    path: "/fav",
    component: _f1f418ec,
    name: "fav"
  }, {
    path: "/flipTest",
    component: _f1f3e734,
    name: "flipTest"
  }, {
    path: "/index_old",
    component: _9ee98c34,
    name: "index_old"
  }, {
    path: "/info",
    component: _e796e69c,
    name: "info"
  }, {
    path: "/infos",
    component: _3b46af18,
    name: "infos"
  }, {
    path: "/main",
    component: _467d5500,
    name: "main"
  }, {
    path: "/myDistricts",
    component: _32ca9148,
    name: "myDistricts"
  }, {
    path: "/mypage",
    component: _1ec8a402,
    name: "mypage"
  }, {
    path: "/notices",
    component: _03c4230a,
    name: "notices"
  }, {
    path: "/onlineCongregation",
    component: _4b58f140,
    name: "onlineCongregation"
  }, {
    path: "/organization",
    component: _6469000c,
    name: "organization"
  }, {
    path: "/politic",
    component: _7d6b6501,
    name: "politic"
  }, {
    path: "/posts",
    component: _7c59e942,
    name: "posts"
  }, {
    path: "/posts_old",
    component: _4e93b92a,
    name: "posts_old"
  }, {
    path: "/qnas",
    component: _d4f3ba54,
    name: "qnas"
  }, {
    path: "/quiz",
    component: _6ae95e9c,
    name: "quiz"
  }, {
    path: "/rank",
    component: _179c67b3,
    name: "rank"
  }, {
    path: "/register",
    component: _10233e27,
    name: "register"
  }, {
    path: "/researchPoll",
    component: _6e508e01,
    name: "researchPoll"
  }, {
    path: "/scraps",
    component: _4656406e,
    name: "scraps"
  }, {
    path: "/share",
    component: _63c01e4e,
    name: "share"
  }, {
    path: "/somoim",
    component: _01bb8fa9,
    name: "somoim"
  }, {
    path: "/staffs",
    component: _304b355a,
    name: "staffs"
  }, {
    path: "/stuff",
    component: _5619f3ba,
    name: "stuff"
  }, {
    path: "/survey",
    component: _3088133e,
    name: "survey"
  }, {
    path: "/test",
    component: _1032cece,
    name: "test"
  }, {
    path: "/webtoon",
    component: _007c84c6,
    name: "webtoon"
  }, {
    path: "/auth/edit",
    component: _7b17d33d,
    name: "auth-edit"
  }, {
    path: "/auth/login",
    component: _197c1d74,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _7be58006,
    name: "auth-logout"
  }, {
    path: "/auth/password",
    component: _6cb429ce,
    name: "auth-password"
  }, {
    path: "/auth/register",
    component: _35c54c54,
    name: "auth-register"
  }, {
    path: "/auth/success",
    component: _60f0f0c0,
    name: "auth-success"
  }, {
    path: "/chat/ask",
    component: _d988b174,
    name: "chat-ask"
  }, {
    path: "/chat/create",
    component: _15719ebf,
    name: "chat-create"
  }, {
    path: "/chat/live",
    component: _4f93856f,
    name: "chat-live"
  }, {
    path: "/chat/location",
    component: _5f75fa38,
    name: "chat-location"
  }, {
    path: "/chat/login",
    component: _02df4af6,
    name: "chat-login"
  }, {
    path: "/chat/test",
    component: _928e53d6,
    name: "chat-test"
  }, {
    path: "/chat/view",
    component: _425a8aa8,
    name: "chat-view"
  }, {
    path: "/confirm/jay",
    component: _24fe7d02,
    name: "confirm-jay"
  }, {
    path: "/confirm/jay_old",
    component: _34b0df67,
    name: "confirm-jay_old"
  }, {
    path: "/confirm/ref",
    component: _cb0b8120,
    name: "confirm-ref"
  }, {
    path: "/contacts/create",
    component: _32f94ce4,
    name: "contacts-create"
  }, {
    path: "/contacts/evaluateList",
    component: _5080673f,
    name: "contacts-evaluateList"
  }, {
    path: "/contents/about",
    component: _a0009748,
    name: "contents-about"
  }, {
    path: "/contents/customer",
    component: _66da575f,
    name: "contents-customer"
  }, {
    path: "/contents/declares",
    component: _d879db6c,
    name: "contents-declares"
  }, {
    path: "/contents/greeting",
    component: _050c9b8c,
    name: "contents-greeting"
  }, {
    path: "/contents/privacyPolicy",
    component: _d5532bae,
    name: "contents-privacyPolicy"
  }, {
    path: "/contents/settings",
    component: _5109e924,
    name: "contents-settings"
  }, {
    path: "/eventPosts/create",
    component: _8e9ee1c4,
    name: "eventPosts-create"
  }, {
    path: "/game/bricks",
    component: _7f6b747e,
    name: "game-bricks"
  }, {
    path: "/game/shooters",
    component: _1d38da93,
    name: "game-shooters"
  }, {
    path: "/infos/approvals",
    component: _3373be32,
    name: "infos-approvals"
  }, {
    path: "/infos/commissioner_namelist",
    component: _c164b85c,
    name: "infos-commissioner_namelist"
  }, {
    path: "/infos/create",
    component: _de269e6c,
    name: "infos-create"
  }, {
    path: "/infos/create_daily_report",
    component: _0ef0b11f,
    name: "infos-create_daily_report"
  }, {
    path: "/infos/daily_report",
    component: _6e18a668,
    name: "infos-daily_report"
  }, {
    path: "/infos/namelist",
    component: _7764e977,
    name: "infos-namelist"
  }, {
    path: "/mypage/attendance",
    component: _f4a2932e,
    name: "mypage-attendance"
  }, {
    path: "/mypage/card",
    component: _4e99dde0,
    name: "mypage-card"
  }, {
    path: "/mypage/choochun",
    component: _6995f0c3,
    name: "mypage-choochun"
  }, {
    path: "/mypage/comments",
    component: _76746df4,
    name: "mypage-comments"
  }, {
    path: "/mypage/likePosts",
    component: _6197e6a8,
    name: "mypage-likePosts"
  }, {
    path: "/mypage/mapView",
    component: _c753085e,
    name: "mypage-mapView"
  }, {
    path: "/mypage/meetings",
    component: _3141c8d0,
    name: "mypage-meetings"
  }, {
    path: "/mypage/newcard",
    component: _76beaa40,
    name: "mypage-newcard"
  }, {
    path: "/mypage/participants",
    component: _2a05dda0,
    name: "mypage-participants"
  }, {
    path: "/mypage/posts",
    component: _714ee2fa,
    name: "mypage-posts"
  }, {
    path: "/mypage/qr",
    component: _4f466da1,
    name: "mypage-qr"
  }, {
    path: "/mypage/sharePosts",
    component: _418bf598,
    name: "mypage-sharePosts"
  }, {
    path: "/notices/create",
    component: _c716ee18,
    name: "notices-create"
  }, {
    path: "/organization/brief",
    component: _408470a5,
    name: "organization-brief"
  }, {
    path: "/organization/chart",
    component: _47b619ee,
    name: "organization-chart"
  }, {
    path: "/organization/list",
    component: _6c3386a9,
    name: "organization-list"
  }, {
    path: "/organization/manage",
    component: _28660ab0,
    name: "organization-manage"
  }, {
    path: "/organization/myTown",
    component: _66c08069,
    name: "organization-myTown"
  }, {
    path: "/organization/report",
    component: _56c8e882,
    name: "organization-report"
  }, {
    path: "/popular/rank",
    component: _8312c4ae,
    name: "popular-rank"
  }, {
    path: "/popular/statistics",
    component: _5f736860,
    name: "popular-statistics"
  }, {
    path: "/posts_old/create",
    component: _a8d09258,
    name: "posts_old-create"
  }, {
    path: "/posts/create",
    component: _369789bc,
    name: "posts-create"
  }, {
    path: "/qnas/create",
    component: _42f282a8,
    name: "qnas-create"
  }, {
    path: "/qnas/list",
    component: _df59b36c,
    name: "qnas-list"
  }, {
    path: "/quiz/complete",
    component: _423f9ddf,
    name: "quiz-complete"
  }, {
    path: "/staffs/create",
    component: _00cfc0a4,
    name: "staffs-create"
  }, {
    path: "/staffs/manage",
    component: _43de608d,
    name: "staffs-manage"
  }, {
    path: "/stuff/assembly",
    component: _43188c30,
    name: "stuff-assembly"
  }, {
    path: "/stuff/education",
    component: _36e02d3c,
    name: "stuff-education"
  }, {
    path: "/stuff/mission",
    component: _62b30460,
    name: "stuff-mission"
  }, {
    path: "/survey/list",
    component: _13dff8a2,
    name: "survey-list"
  }, {
    path: "/survey/survey_lee",
    component: _26345db0,
    name: "survey-survey_lee"
  }, {
    path: "/test/t",
    component: _2b29828a,
    name: "test-t"
  }, {
    path: "/volunteer/listView",
    component: _0bb4bf4c,
    name: "volunteer-listView"
  }, {
    path: "/volunteer/register",
    component: _1e09ed8c,
    name: "volunteer-register"
  }, {
    path: "/infos/branch_manager/commissioner_namelist",
    component: _24d4d982,
    name: "infos-branch_manager-commissioner_namelist"
  }, {
    path: "/infos/branch_manager/daily_report",
    component: _408b8f4a,
    name: "infos-branch_manager-daily_report"
  }, {
    path: "/infos/general_manager/daily_report",
    component: _0064bc01,
    name: "infos-general_manager-daily_report"
  }, {
    path: "/mypage/bannerPicture/edit",
    component: _33fd8e47,
    name: "mypage-bannerPicture-edit"
  }, {
    path: "/organization/chart/leave",
    component: _72571a71,
    name: "organization-chart-leave"
  }, {
    path: "/organization/manage/appoint",
    component: _db8a0a3c,
    name: "organization-manage-appoint"
  }, {
    path: "/organization/manage/approval",
    component: _2c7ac7d0,
    name: "organization-manage-approval"
  }, {
    path: "/organization/manage/approvalRequests",
    component: _137185fc,
    name: "organization-manage-approvalRequests"
  }, {
    path: "/organization/manage/dismiss",
    component: _34a5b76b,
    name: "organization-manage-dismiss"
  }, {
    path: "/organization/manage/distribute",
    component: _4c3337b6,
    name: "organization-manage-distribute"
  }, {
    path: "/organization/manage/myApprovalList",
    component: _7ab51f22,
    name: "organization-manage-myApprovalList"
  }, {
    path: "/organization/manage/resign",
    component: _9cdedaf6,
    name: "organization-manage-resign"
  }, {
    path: "/organization/myTown/searchPage",
    component: _3b173e60,
    name: "organization-myTown-searchPage"
  }, {
    path: "/organization/report/list",
    component: _3cdd0e04,
    name: "organization-report-list"
  }, {
    path: "/organization/report/survey_lee",
    component: _15e2e68a,
    name: "organization-report-survey_lee"
  }, {
    path: "/organization/report/survey2",
    component: _2b9192c5,
    name: "organization-report-survey2"
  }, {
    path: "/organization/statistic/branch",
    component: _269b1ce8,
    name: "organization-statistic-branch"
  }, {
    path: "/organization/statistic/state",
    component: _ccb28586,
    name: "organization-statistic-state"
  }, {
    path: "/stuff/mission/create",
    component: _1b61d25e,
    name: "stuff-mission-create"
  }, {
    path: "/stuff/mission/createChild",
    component: _3536b4e4,
    name: "stuff-mission-createChild"
  }, {
    path: "/organization/chart/leave/resign",
    component: _08e69de1,
    name: "organization-chart-leave-resign"
  }, {
    path: "/organization/manage/dismiss/dismissMember",
    component: _57fdce5d,
    name: "organization-manage-dismiss-dismissMember"
  }, {
    path: "/organization/manage/distribute/List",
    component: _89cb4cec,
    name: "organization-manage-distribute-List"
  }, {
    path: "/",
    component: _f91b9904,
    name: "index"
  }, {
    path: "/organization/brief/sub/:id?",
    component: _51afc404,
    name: "organization-brief-sub-id"
  }, {
    path: "/organization/manage/approvalRequests/:id",
    component: _7860d764,
    name: "organization-manage-approvalRequests-id"
  }, {
    path: "/organization/manage/myApprovalList/:id",
    component: _31e4820a,
    name: "organization-manage-myApprovalList-id"
  }, {
    path: "/organization/report/list/:id",
    component: _0cc27d28,
    name: "organization-report-list-id"
  }, {
    path: "/organization/brief/:id",
    component: _b95f4f66,
    name: "organization-brief-id"
  }, {
    path: "/organization/report/:id",
    component: _8e711c32,
    name: "organization-report-id"
  }, {
    path: "/stuff/mission/:id",
    component: _5412c270,
    name: "stuff-mission-id"
  }, {
    path: "/survey/list/:id",
    component: _7a1088ec,
    name: "survey-list-id"
  }, {
    path: "/activity/:id",
    component: _1e84b8be,
    name: "activity-id"
  }, {
    path: "/eventPosts/:id",
    component: _94a1cbf0,
    name: "eventPosts-id"
  }, {
    path: "/notices/:id",
    component: _5999401c,
    name: "notices-id"
  }, {
    path: "/posts_old/:id",
    component: _2331fe12,
    name: "posts_old-id"
  }, {
    path: "/posts/:id",
    component: _d71557ac,
    name: "posts-id"
  }, {
    path: "/qnas/:id",
    component: _6ccae2be,
    name: "qnas-id"
  }, {
    path: "/quiz/:id",
    component: _2f365804,
    name: "quiz-id"
  }, {
    path: "/scraps/:id",
    component: _627a4071,
    name: "scraps-id"
  }, {
    path: "/somoim/:id",
    component: _670b6851,
    name: "somoim-id"
  }, {
    path: "/webtoon/:id",
    component: _58ec8345,
    name: "webtoon-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
